import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../../APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { MainBody, EditSubscriptionResponse } from './editSubscriptionModel'

type InitialState = {
  loading: boolean
  editresponse: EditSubscriptionResponse
  succssMessage: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  editresponse: {} as EditSubscriptionResponse,
  succssMessage: '',
  error: '',
}

export const editSubscriptionApi = createAsyncThunk<EditSubscriptionResponse, MainBody>(
  'editSubscriptionApi/editSubscriptionApi',
  async (payload: MainBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .put(Url.backEndURL + `/api/account/${payload.id}`, payload.body, { headers })
      .then((editresponse) => editresponse.data)
      .catch((error) => rejectWithValue(error.response.data[0].detail))
  },
)

const editSubscriptionSlice = createSlice({
  name: 'editSubscriptionApi',
  initialState,
  reducers: {
    editSubscriptionClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    editSubscriptionClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.editresponse = {} as EditSubscriptionResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editSubscriptionApi.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      editSubscriptionApi.fulfilled,
      (state, action: PayloadAction<EditSubscriptionResponse>) => {
        state.loading = false
        state.editresponse = action.payload
        state.succssMessage =
        action?.payload?.succssMessage !== undefined
          ? action?.payload?.succssMessage !== ''
            ? action?.payload?.succssMessage
            : 'Subscription Edited successful'
          : 'Subscription Edited successful'
      state.error = ''
    })
    builder.addCase(editSubscriptionApi.rejected, (state, action) => {
      state.loading = false
      state.editresponse = {} as EditSubscriptionResponse
      state.succssMessage = ''
      state.error = String(action.payload!)
    })
  },
})
export const { editSubscriptionClearMsg, editSubscriptionClearRes } = editSubscriptionSlice.actions

export default editSubscriptionSlice.reducer
