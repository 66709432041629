import React from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Define the styled LinearProgress component with border and colors
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: 2,
  justifyContent: "center",
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.grey[300],
  '& .MuiLinearProgress-bar': {
    borderRadius: 2,
  },
}));

// Define the props for CustomProgressBar
interface CustomProgressBarProps {
  value: number;
}

const CustomProgressBar: React.FC<CustomProgressBarProps> = ({ value }) => {
  // Determine the bar color based on value
  let barColor: string;
  if (value === Infinity) barColor = '#E0E0E0';
  else if (value < 20) barColor = '#efbb5aa3';
  else if (value < 95) barColor = '#088208a3';
  else barColor = '#f44336';
  return (
    <Box position="relative" display="flex" alignItems="center" width="100%">
      <BorderLinearProgress
        variant="determinate"
        value={Math.min(value, 100)}
        sx={{
          width: '100%',
          '& .MuiLinearProgress-bar': { backgroundColor: barColor },
        }}
      />
      <Box
        position="absolute"
        left="50%"
        top="50%"
        style={{
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Typography variant="caption" component="div" color="textPrimary">
          {value === Infinity
            ? value > 100
              ? "n/a"
              : `${value}%`
            : `${value}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomProgressBar;
