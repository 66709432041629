import { checkReturnValue, renderApplogieDate } from 'components/Utility'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'reduxStore/hooks'
import { Result } from 'reduxStore/subcription/searchTagList/Model'

export function SubscriptionListByTag() {
  const searchTagListValue = useAppSelector((state) => state.searchTagList)
  const [subscriptionList, setSubscriptionList] = useState([] as any[])
  // Modify dataset for subscription table for subscription Tag list search
  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      if (searchTagListValue.searchTagSource.results !== undefined) {
        let array = searchTagListValue.searchTagSource.results.map((item: Result) => {
          return {
            id: 'id' in item ? item.id : 0,
            alert: 'alert_count' in item ? Number(item.alert_count) : 0,
            accountId:
              checkReturnValue(item.account_id) !== 'Not Set'
                ? checkReturnValue(item.account_id)
                : 0,
            name: checkReturnValue(item.vendor_name),
            tableName:
              checkReturnValue(item.vendor_name) !== 'Not Set'
                ? checkReturnValue(item?.vendor_alias) !== 'Not Set'
                  ? checkReturnValue(item?.vendor_alias) !== ''
                    ? checkReturnValue(item?.vendor_alias)
                    : item.vendor_name
                  : item.vendor_name
                : null,
            account: checkReturnValue(item.account_number),
            modifiedRenewalDate:
              checkReturnValue(item.renewal_date) !== 'Not Set'
                ? renderApplogieDate(checkReturnValue(item.renewal_date))
                : null,
            userName: checkReturnValue(item?.owner?.user?.username),
            userFirstName:
              checkReturnValue(item?.owner?.user?.first_name) !== 'Not Set'
                ? item?.owner?.user?.first_name
                : null,
            userLastName:
              checkReturnValue(item?.owner?.user?.last_name) !== 'Not Set'
                ? item?.owner?.user?.last_name
                : null,
            userEmail: checkReturnValue(item?.owner?.user?.email),
            department: checkReturnValue(item?.department?.name),
            yearlySpend:
              checkReturnValue(item?.yearly_spend) !== 'Not Set'
                ? Number(checkReturnValue(item?.yearly_spend))
                : 0,
            yearlyBudget:
              checkReturnValue(item?.yearly_budget) !== 'Not Set'
                ? Number(checkReturnValue(item?.yearly_budget))
                : 0,
            quarterlySpend:
              checkReturnValue(item?.quarterly_spend) !== 'Not Set'
                ? Number(checkReturnValue(item?.quarterly_spend))
                : 0,
            quarterlyBudget:
              checkReturnValue(item?.quarterly_budget) !== 'Not Set'
                ? Number(checkReturnValue(item?.quarterly_budget))
                : 0,
            monthlySpend:
              checkReturnValue(item?.monthly_spend) !== 'Not Set'
                ? Number(checkReturnValue(item?.monthly_spend))
                : 0,
            monthlyBudget:
              checkReturnValue(item?.monthly_budget) !== 'Not Set'
                ? Number(checkReturnValue(item?.monthly_budget))
                : 0,
            status: checkReturnValue(item?.status?.name),
            owner:
              checkReturnValue(item?.owner) !== 'Not Set' ? checkReturnValue(item?.owner) : null,
            departmentList:
              checkReturnValue(item?.department) !== 'Not Set'
                ? checkReturnValue(item?.department)
                : null,
            description:
              checkReturnValue(item?.department) !== 'Not Set'
                ? checkReturnValue(item?.department)
                : '',
            statustList:
              checkReturnValue(item?.department) !== 'Not Set'
                ? checkReturnValue(item?.status)
                : null,
            monthly_projected_cost:
              checkReturnValue(item?.monthly_projected_cost) !== 'Not Set'
                ? checkReturnValue(item?.monthly_projected_cost)
                : null,
            aliasName:
              checkReturnValue(item?.vendor_alias) !== 'Not Set'
                ? checkReturnValue(item?.vendor_alias) !== ''
                  ? checkReturnValue(item?.vendor_alias)
                  : '---'
                : '---',
          }
        }) as any
        setSubscriptionList(array)
      } else setSubscriptionList([] as any[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTagListValue])
  return subscriptionList
}
