import * as React from 'react'
import {
  Alert,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Stack,
  CircularProgress,
  Box,
  CardMedia,
  Divider,
  CardHeader,
  Link,
  CssBaseline,
  ThemeProvider,
  createTheme,
  Icon,
  AlertColor,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'reduxStore/store'
import { forgotPassword, updateForgotPasswordClearRes } from 'reduxStore/forgotPassword/forgotPasswordSlice'
import { useAppSelector } from 'reduxStore/hooks'
import FeatureImageLogin from '../images/applogie_logo_white.svg'
import { useNavigate } from 'react-router-dom'
import ArrowImageIcon from '../images/ArrowBackFilled.svg'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
export default function ForgotPassword() {
  const dispatch = useDispatch<AppDispatch>()
  // Call forgot password store
  const forgotPasswordDetails = useAppSelector((state) => state.forgotPassword)
  const [showErrorMsgView, setShowErrorMsgView] = React.useState(false)
  const [emailFieldError, setEmailFieldError] = React.useState(false)
  const emailFieldNullMsg = 'Please complete this required field'
  const [email, setEmail] = React.useState('')
  const [forgotpwMsg, setForgotpwMsg] = React.useState('')
  const [forgotPasswordMsgType, setForgotPasswordMsgType] = React.useState<AlertColor>('success')
  const closeForgotPasswordSnackbar = () => {
    dispatch(updateForgotPasswordClearRes())
    setForgotpwMsg('')
  }
  let navigate = useNavigate()
  const userForgotPassword = () => {
    if (email !== '') {
      dispatch(forgotPassword(email))
      setEmailFieldError(false)
    } else {
      setEmailFieldError(true)
    }
  }
  const theme = createTheme({
    palette: {
      primary: {
        main: '#008F70',
      },
      secondary: {
        main: '#330101',
      },
      background: {
        default: "#091231",
      },
      text: {
        primary: '#FFFFF',
      },
    },
  })

  React.useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line no-lone-blocks
    {
      forgotPasswordDetails !== undefined
        ? forgotPasswordDetails.response !== undefined
          ? forgotPasswordDetails.response.message !== undefined
            ? forgotPasswordDetails.response.message!.length > 0
              ? setShowErrorMsgView(true)
              : setShowErrorMsgView(false)
            : setShowErrorMsgView(false)
          : setShowErrorMsgView(false)
        : setShowErrorMsgView(false)
    }
  }, [forgotPasswordDetails])

  React.useEffect(() => {
    if (forgotPasswordDetails?.successMsg !== '') {
      setForgotPasswordMsgType('success')
      setForgotpwMsg(forgotPasswordDetails?.successMsg)
    }
    if (forgotPasswordDetails?.error !== '') {
      setForgotpwMsg(forgotPasswordDetails?.error)
      setForgotPasswordMsgType('error')
    }
  }, [forgotPasswordDetails])

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        columns={{ xs: 4, sm: 8, md: 12 }}
        p={1}
        sx={{ height: 'calc(100vh - 64px)' }}
      >

        <Grid item xs={4} sm={12} md={4}>
          <Grid item sx={{ pb: 2.5 }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item>
                <Icon component="img" src={ArrowImageIcon} style={{ width: 24, height: 24, paddingLeft: 1 }} />
              </Grid>
              <Grid item>
                <Link
                  underline="none"
                  target="_blank"
                  href="https://applogie.com"
                  style={{ cursor: 'pointer', color: '#FFFFFF' }}
                >
                  <Typography variant="body2" style={{ fontSize: "16px", paddingTop: "2px" }}>{'Return to Applogie.com'}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Card sx={{ display: 'flex', width: "auto", borderRadius: "0.3em", }}>
            <Box sx={{ backgroundColor: "#008F70", width: "426px", height: "286px" }}>
              <CardMedia
                component="img"
                sx={{ width: "243px", height: "53px", display: "block", margin: 'auto', marginTop: "7em" }}
                image={FeatureImageLogin}
                alt="ApplogieLogo"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: "426px", height: "286px" }}>
              <CardHeader title="Forgot Password" titleTypographyProps={{
                variant: 'h5',
                fontSize: '24px',
                fontFamily: 'Noto Sans',
                fontWeight: 400,
              }}></CardHeader>
              <Divider />
              <CardContent sx={{
                width: '426px',
                height: '268px',
                padding: '16px',
              }}>
                <CssBaseline />
                <Typography component="div" variant="subtitle1">
                  Enter your emaill address below and we'll send you a link to reset your password.
                </Typography>
                <Box sx={{ pt: 2 }}>
                  <Collapse in={showErrorMsgView}>
                    <Alert
                      icon={false}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setShowErrorMsgView(false)
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      {forgotPasswordDetails.response.message}
                    </Alert>
                  </Collapse>
                </Box>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    id="userName"
                    label="Email Address"
                    variant="standard"
                    fullWidth
                    error={emailFieldError}
                    sx={{ mt: '10' }}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                  />
                  {emailFieldError === true ? (
                    <Typography style={{ color: '#d32f2f' }} sx={{ fontSize: 13 }}>
                      {emailFieldNullMsg}
                    </Typography>
                  ) : null}
                </FormControl>
                <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                  <Grid item sx={{ pt: 2 }}>
                    <Button
                      variant="contained"
                      fullWidth
                      size="large"

                      onClick={() => {
                        userForgotPassword()
                      }}
                      disabled={forgotPasswordDetails?.loading}
                    >
                      {forgotPasswordDetails.loading === true ? (
                        <Stack direction="row" spacing={1}>
                          <Typography> Reset Password</Typography>
                          <Box pl={1}>
                            <CircularProgress color="inherit" size={18} />
                          </Box>
                        </Stack>
                      ) : (
                        'RESET PASSWORD'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>

          </Card>
        </Grid>
        <Grid item sx={{ pt: 2.5 }}>
          <Link
            underline="none"
            onClick={() => navigate('/login')}
            style={{ cursor: 'pointer', color: '#FFFFFF' }}
          >
            <Typography variant="body2" style={{ fontSize: "16px" }}>{'Log In'}</Typography>
          </Link>
        </Grid>
      </Grid>
      <ApplogieSnackbar msg={forgotpwMsg} type={forgotPasswordMsgType} alertClose={closeForgotPasswordSnackbar} />
    </ThemeProvider>
  )
}
