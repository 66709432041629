import { checkReturnValue } from 'components/Utility'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'reduxStore/hooks'
// import { Result } from 'reduxStore/subcription/Active/Model'

export function AllActiveSubscriptionList() {
  const subscriptionType = localStorage.getItem('selectedSubscriptionListTab')
  const subsValue = useAppSelector((state) => state.subscription)
  const ignoredSubscriptionValue = useAppSelector((state) => state.ignoredsubscription)
  const [subscriptionList, setSubscriptionList] = useState([] as any[])

  const location = useLocation()

  // Modify dataset for subscription table
  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      let activeSubscriptionListStoreValue = subsValue?.subscriptions?.results
      let inActiveSubscriptionListStoreValue =
        ignoredSubscriptionValue?.ignoredsubcriptions?.results

      let subscriptionValue =
        subscriptionType! === '0' || location?.pathname === '/dashboard'
          ? activeSubscriptionListStoreValue
          : inActiveSubscriptionListStoreValue

      if (subscriptionValue !== undefined) {
        let array = subscriptionValue.map((item: any) => {
          return {
            id: 'id' in item ? item.id : 0,
            alert: 'alert_count' in item ? Number(item.alert_count) : 0,
            accountId:
              checkReturnValue(item.account_id) !== 'Not Set'
                ? checkReturnValue(item.account_id)
                : 0,
            name: checkReturnValue(item.vendor_name) !== 'Not Set' ? item.vendor_name : null,
            tableName:
              checkReturnValue(item.vendor_name) !== 'Not Set'
                ? checkReturnValue(item?.vendor_alias) !== 'Not Set'
                  ? checkReturnValue(item?.vendor_alias) !== ''
                    ? checkReturnValue(item?.vendor_alias)
                    : item.vendor_name
                  : item.vendor_name
                : null,
            account:
              checkReturnValue(item.account_number) !== 'Not Set' ? item.account_number : null,
            renewalDate:
              checkReturnValue(item.renewal_date) !== 'Not Set'
                ? dayjs(checkReturnValue(item.renewal_date)).format('YYYY-MM-DD')
                : null,
            modifiedRenewalDate:
              checkReturnValue(item.renewal_date) !== 'Not Set'
                ? checkReturnValue(item.renewal_date)
                : null,
            modifiedFilteredRenewalDate:
              checkReturnValue(item.renewal_date) !== 'Not Set'
                ? dayjs(checkReturnValue(item.renewal_date)).format('MMMM YYYY')
                : null,
            userName:
              checkReturnValue(item?.owner?.user?.username) !== 'Not Set'
                ? item?.owner?.user?.username
                : null,
            userFirstName:
              checkReturnValue(item?.owner?.user?.first_name) !== 'Not Set'
                ? item?.owner?.user?.first_name
                : null,
            userLastName:
              checkReturnValue(item?.owner?.user?.last_name) !== 'Not Set'
                ? item?.owner?.user?.last_name
                : null,
            userEmail:
              checkReturnValue(item?.owner?.user?.email) !== 'Not Set'
                ? item?.owner?.user?.email
                : null,
            department:
              checkReturnValue(item?.department?.name) !== 'Not Set'
                ? item?.department?.name
                : null,
            yearlySpend:
              checkReturnValue(item?.yearly_spend) !== 'Not Set'
                ? Number(checkReturnValue(item?.yearly_spend))
                : 0,
            yearlyBudget:
              checkReturnValue(item?.yearly_budget) !== 'Not Set'
                ? Number(checkReturnValue(item?.yearly_budget))
                : 0,
            spendBudget : checkReturnValue(item?.yearly_spend) !== 'Not Set' || checkReturnValue(item?.yearly_budget) !== 'Not Set'
                ? Number(checkReturnValue((item?.yearly_spend * 100) / item?.yearly_budget).toFixed(2))
                : 0,
            quarterlySpend:
              checkReturnValue(item?.quarterly_spend) !== 'Not Set'
                ? Number(checkReturnValue(item?.quarterly_spend))
                : 0,
            quarterlyBudget:
              checkReturnValue(item?.quarterly_budget) !== 'Not Set'
                ? Number(checkReturnValue(item?.quarterly_budget))
                : 0,
            monthlySpend:
              checkReturnValue(item?.monthly_spend) !== 'Not Set'
                ? Number(checkReturnValue(item?.monthly_spend))
                : 0,
            monthlyBudget:
              checkReturnValue(item?.monthly_budget) !== 'Not Set'
                ? Number(checkReturnValue(item?.monthly_budget))
                : 0,
            status: checkReturnValue(item?.status?.name) !== 'Not Set' ? item?.status?.name : null,
            owner:
              checkReturnValue(item?.owner) !== 'Not Set' ? checkReturnValue(item?.owner) : null,
            departmentList:
              checkReturnValue(item?.department) !== 'Not Set'
                ? checkReturnValue(item?.department)
                : null,
            description:
              checkReturnValue(item?.description) !== 'Not Set'
                ? checkReturnValue(item?.description)
                : '',
            monthly_projected_cost:
              checkReturnValue(item?.monthly_projected_cost) !== 'Not Set'
                ? checkReturnValue(item?.monthly_projected_cost)
                : null,
            aliasName:
              checkReturnValue(item?.vendor_alias) !== 'Not Set'
                ? checkReturnValue(item?.vendor_alias) !== ''
                  ? checkReturnValue(item?.vendor_alias)
                  : '---'
                : '---',
          }
        }) as any
        setSubscriptionList(array)
      } else setSubscriptionList([] as any[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoredSubscriptionValue, subsValue, subscriptionType])
  return subscriptionList
}
